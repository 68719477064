import React, { useEffect, useState } from "react";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Box, Button, Typography, Card, CardContent, CardActions, LinearProgress } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

const CLIENT_ID = "4ak5iilqak1v3ecp1i1v8n3tr3";
const REDIRECT_URI = "https://commissions.lamutuellecommunale.com";
const DOMAIN = "hps-commission-user-pool.auth.eu-west-3.amazoncognito.com";
const PROVIDER_NAME = "hps-commission-azure-idp"; // Nom du fournisseur d'identité
const API_URL = "https://kw2bjkpguc.execute-api.eu-west-3.amazonaws.com/prod/generate-presigned-url"; // URL de l'API Chalice

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");

  useEffect(() => {
    // Vérifier si l'utilisateur est redirigé après authentification avec un code
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");

    if (authCode) {
      exchangeAuthCodeForTokens(authCode);
    } else {
      setIsAuthenticated(isUserLoggedIn());
    }
  }, []);

  function isUserLoggedIn() {
    const idToken = localStorage.getItem("cognitoIdToken");
    return !!idToken;
  }

  const handleLogin = () => {
    // Rediriger vers le fournisseur d'identité via Cognito
    const loginUrl = `https://${DOMAIN}/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&identity_provider=${PROVIDER_NAME}&scope=openid+profile`;
    window.location.href = loginUrl;
  };

  const handleSignOut = () => {
    // Supprimer les tokens stockés
    localStorage.removeItem("cognitoIdToken");
    localStorage.removeItem("cognitoAccessToken");
    localStorage.removeItem("cognitoRefreshToken");
    setIsAuthenticated(false);
    window.location.href = REDIRECT_URI; // Rediriger vers la page d'accueil après déconnexion
  };

  const exchangeAuthCodeForTokens = async (authCode) => {
    try {
      const tokenUrl = `https://${DOMAIN}/oauth2/token`;
      const response = await axios.post(
        tokenUrl,
        new URLSearchParams({
          grant_type: "authorization_code",
          client_id: CLIENT_ID,
          redirect_uri: REDIRECT_URI,
          code: authCode,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { id_token, access_token, refresh_token } = response.data;

      // Stocker les tokens dans localStorage
      localStorage.setItem("cognitoIdToken", id_token);
      localStorage.setItem("cognitoAccessToken", access_token);
      localStorage.setItem("cognitoRefreshToken", refresh_token);

      setIsAuthenticated(true);
    } catch (error) {
      console.error("Erreur lors de l'échange du code d'autorisation :", error);
    }
  };

  const handleFileUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    try {
      // Demander une URL présignée à l'API Chalice
      const response = await axios.post(
        API_URL,
        {
          file_name: file.name,
          file_type: file.type,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("cognitoIdToken")}`,
          },
        }
      );

      const presignedUrl = response.data.presigned_url;

      // Upload du fichier vers S3
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });

      setUploadStatus("success");
    } catch (error) {
      setUploadStatus("error");
      console.error("Erreur lors de l'upload :", error);
    }
  };

  // Si l'utilisateur est authentifié, afficher la page avec Dropzone et Sign Out
  if (isAuthenticated) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
        <Card sx={{ maxWidth: 500, padding: 3, textAlign: "center" }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Bienvenue, vous êtes connecté
            </Typography>
            <Dropzone onDrop={handleFileUpload}>
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  sx={{
                    border: "2px dashed #ccc",
                    padding: 3,
                    marginTop: 2,
                    cursor: "pointer",
                    backgroundColor: "#f9f9f9",
                    textAlign: "center",
                  }}
                >
                  <input {...getInputProps()} />
                  <CloudUploadIcon fontSize="large" />
                  <Typography variant="body1">Déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers</Typography>
                </Box>
              )}
            </Dropzone>
            {uploadProgress > 0 && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="textSecondary">{uploadProgress}%</Typography>
              </Box>
            )}
            {uploadStatus === "success" && (
              <Typography variant="body2" color="green" mt={2}>
                Merci, votre fichier a été reçu et son intégration aura lieu dans les 5 prochaines minutes. Vous recevrez un e-mail une fois que l'intégration sera terminée.
              </Typography>
            )}
            {uploadStatus === "error" && (
              <Typography variant="body2" color="red" mt={2}>
                Une erreur est survenue, merci de réessayer plus tard ou de nous contacter directement.
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LogoutIcon />}
              onClick={handleSignOut}
              fullWidth
            >
              Se déconnecter
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }

  // Si l'utilisateur n'est pas authentifié, rediriger vers la page de connexion
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <Card sx={{ maxWidth: 400, padding: 3, textAlign: "center" }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Veuillez vous connecter pour accéder à cette page
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LoginIcon />}
            onClick={handleLogin}
            fullWidth
          >
            Se connecter
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default App;
